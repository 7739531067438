<template>
  <div>
    <div  class="mb-5">
      <!-- <div v-if="$store.getters['productGenerator/isNotEditing']" class="mb-5"> -->
      <add-component-slot-to-product class="mr-3"/>

      <create-component-type/>
    </div>
    <div>
      <slots-display/>
    </div>
  </div>
</template>

<script>
import AddComponentSlotToProduct from '@/views/ProductGenerator/components/ComponentsSelector/AddComponentSlotToProduct'
import SlotsDisplay from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay'
import CreateComponentType from '@/views/ProductManagement/ComponentTypes/CreateComponentType'

export default {
  name: 'ComponentsSelector',
  components: {
    CreateComponentType,
    SlotsDisplay,
    AddComponentSlotToProduct
  }
}
</script>
