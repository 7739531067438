<template>
  <div :class="{'selected' : selected}" class="single-component-selector">
    <div @click="toggleSelection" class="flex items-center cursor-pointer">
      <div class="mr-2">
        <div v-show="selected">
          <i class="fal fa-minus-square fa-2x"></i>
        </div>
        <div v-show="!selected">
          <i class="fal fa-plus-square fa-2x"></i>
        </div>
      </div>
      <div>
        <div class="font-weight-bold">
          {{ component.internal_name }}
        </div>
        <div>
          <small>({{ component.name }})</small>
        </div>
      </div>
    </div>
    <div>
      <div v-show="selected">
        <div class="flex items-baseline justify-between my-2">
          <div>in Stoc: <strong>{{ component.stock }}</strong></div>
          <div>Pret vanzare: <strong>{{ component.price }}</strong></div>
          <div>Pret achizitie: <strong>{{ component.buying_price }}</strong></div>
          <fallback-stock v-model="status"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {find} from 'lodash'
import FallbackStock
  from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/SingleComponentSelector/FallbackStock'

export default {
  name: 'SingleComponentSelector',
  components: {FallbackStock},
  props: {
    component: {
      required: true
    },
    componentTypeSlot: {
      required: true
    }
  },
  computed: {
    uuid () {
      return this.componentTypeSlot.uuid
    },
    defaultStatus () {
      return this.$store.getters['general/defaultProductStatus']
    },
    selected () {
      return !!find(this.componentTypeSlot.components, (o) => o.id === this.component.id)
    },
    status :{
      get () {
        if (this.selected) {
          const currentComponentInSelectedSlot = find(this.componentTypeSlot.components, (o) => o.id === this.component.id)
          return currentComponentInSelectedSlot.status
        } else {
          return this.defaultStatus
        }
      },
      set (value) {
        this.$store.dispatch('productGenerator/changeStatusOnComponent', {
          id: this.component.id,
          status: value,
          uuid: this.uuid
        })
      }
    }
  },
  methods: {
    toggleSelection () {
      if (this.selected) {
        this.$store.dispatch('productGenerator/deselectComponent', {
          id: this.component.id,
          uuid: this.uuid
        })
      } else {
        this.$store.dispatch('productGenerator/selectComponent', {
          id: this.component.id,
          status: this.status,
          uuid: this.uuid
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.single-component-selector {
  @apply p-2 border-0 border-solid border-b border-grey;
  &.selected {
    @apply bg-grey-light bg-opacity-50;
  }
}
</style>