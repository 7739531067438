<template>
  <div class="px-3">
    <div class="custom-table-wrapper">
      <div class="flex h-12 items-center justify-evenly">
        <!-- <div v-if="$store.getters['productGenerator/isNotEditing']" class="flex h-12 items-center justify-evenly"> -->
        <move-left-button :component-type-slot="componentTypeSlot"/>
        <delete-button @delete="deleteSlot"/>
        <move-right-button :component-type-slot="componentTypeSlot"/>
      </div>
      <h3 class="text-center">{{ componentType.name }}</h3>
      <div>
        <v-expansion-panels v-model="openedSlot">
          <v-expansion-panel v-for="(components, groupName) in groupedComponents" :key="groupName">
            <v-expansion-panel-header>
              {{ groupName }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <single-component-selector
                v-for="component in components"
                :key="component.id"
                :component="component"
                :component-type-slot="componentTypeSlot"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <quick-add-component :component-type="componentType" :components="components" @created="componentCreated"/>
  </div>
</template>

<script>
import {find} from 'lodash'
import QuickAddComponent
  from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/QuickAddComponent'
import MoveLeftButton
  from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/MoveLeftButton'
import MoveRightButton
  from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/MoveRightButton'
import SingleComponentSelector
  from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/SingleComponentSelector'
import DeleteButton from '@/components/general-form/DeleteButton'
import {groupBy} from '@/helpers'

export default {
  name: 'SingleSlotDisplay',
  components: {
    DeleteButton,
    SingleComponentSelector,
    MoveRightButton,
    MoveLeftButton,
    QuickAddComponent
  },
  props: {
    componentTypeSlot: {
      required: true
    }
  },
  computed: {
    groupedComponents () {
      return groupBy(this.components, 'group_name')
    },
    cachedComponentTypes () {
      return this.$store.getters['productGenerator/cachedComponentTypes']
    },
    componentType () {
      return find(this.cachedComponentTypes, (o) => o.id === this.componentTypeSlot.component_type_id)
    },
    components () {
      return this.componentType.components
    }
  },
  data () {
    return {
      search: '',
      openedSlot: -1
    }
  },
  methods: {
    deleteSlot () {
      this.$store.dispatch('productGenerator/removeSlot', this.componentTypeSlot)
    },
    updateSelectedStatus (data) {
      console.log(data)
    },
    componentCreated (data) {
      this.components.push(data)
      this.selectedComponents.push(data)
    }
  },
  created () {
    if (Object.keys(this.groupedComponents).length === 1) {
      this.openedSlot = 0
    }
  }
}
</script>
<!--suppress CssUnusedSymbol, CssUnknownProperty -->
<style scoped>
.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.text-start:first-child {
  width: 1px !important;
}

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper {
  scrollbar-width: thin;
  scrollbar-color: #707070 #d5d5d5;
}

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #d5d5d5;
  border-radius: 10px;
}

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 5px;
  padding-right: 5px;
}

.v-data-table__expanded {
  background: red;
}
</style>
