<template>
  <div class="flex flex-col">
    <v-select
      v-model="selectedNameRecipe"
      :items="nameGenerationMethods"
      item-text="value"
      item-value="key"
      label="Alege metoda de generare a numelui scurt al produselor"
    ></v-select>
    <error-messages :errors="errors.short_name_recipe"/>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'ShortNameRecipe',
  components: {ErrorMessages},
  computed: {
    nameGenerationMethods () {
      return this.$store.getters['productGenerator/nameGenerationMethods']
    },
    errors () {
      return this.$store.getters['productGenerator/errors']
    },
    selectedNameRecipe: {
      get () {
        return this.$store.getters['productGenerator/shortNameRecipe']
      },
      set (value) {
        this.$store.commit('productGenerator/SET_SHORT_NAME_RECIPE', value)
      }
    }
  }
}
</script>
