<template>
  <div class="text-right">
    <vs-button @click="isPopupActive = true" type="relief" size="small" class="text-white my-2">Adauga Componenta noua</vs-button>
    <vs-popup :active.sync="isPopupActive" :title="`Componenta Noua: ${componentType.name}`" button-close-hidden
              @close="closePopup">
      <vs-tabs alignment="fixed">
        <vs-tab label="Simpla">
          <add-new-simple-component ref="insideComponent" :component-type="componentType" @close="isPopupActive=false"
                                    @created="componentCreated"/>
        </vs-tab>
        <vs-tab label="Combinata">
          <add-new-computed-component ref="insideComponent" :components="components" :component-type="componentType" @close="isPopupActive=false"
                                      @created="componentCreated"/>
        </vs-tab>
        <vs-tab label="Multipla">
          <add-new-multiple-component ref="insideComponent" :components="components" :component-type="componentType" @close="isPopupActive=false"
                                      @created="componentCreated"/>
        </vs-tab>
      </vs-tabs>
    </vs-popup>
  </div>
</template>

<script>
import AddNewSimpleComponent from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/QuickAddComponent/AddNewSimpleComponent'
import AddNewComputedComponent from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/QuickAddComponent/AddNewComputedComponent'
import AddNewMultipleComponent from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay/QuickAddComponent/AddNewMultipleComponent'

export default {
  name: 'QuickAddComponent',
  components: {AddNewMultipleComponent, AddNewComputedComponent, AddNewSimpleComponent},
  data () {
    return {
      isPopupActive: false
    }
  },
  props: {
    componentType: {
      required: true
    },
    components: {
      required: true
    }
  },
  methods: {
    closePopup () {
      this.$refs.insideComponent.cancel()
    },
    componentCreated (component) {
      this.$emit('created', component)
    }
  }
}
</script>
