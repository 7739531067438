<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="light-gray" small>
        Alege extraoptiunile pentru acest produs
      </v-btn>
    </template>

    <v-card>
      <v-card-title class=""> Alege categoriile de extraoptiuni </v-card-title>

      <v-card-text>
        <v-autocomplete
          v-model="selectOption"
          :items="extraOptions"
          :label="label"
          :loading="loading"
          :return-object="true"
          :search-input.sync="search"
          cache-items
          class="filter-select text-sm"
          clearable
          hide-details
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="close"> Anuleaza </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="primary-button" @click="select"> Alege Extra optiunea </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import ProductExtraWarrantySelector from '@/components/autocompletes/ProductExtraWarrantySelector'

export default {
  name: "ExtraOptionsSelectorDialog",
  props: {
    extraOptions: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      warranty: "",
      selectOption: {},
      label: "Alegeti categoria extra optiunilor",
      search: "",
      loading: false,
    };
  },
  methods: {
    select() {
      if (this.selectOption) {
        console.log(this.selectOption);
        this.$store.dispatch(
          "productGenerator/selectExtraOption",
          this.selectOption
        );
      }
      this.close();
    },
    close() {
      this.selectOption = {};
      this.dialog = false;
    },
  },
};
</script>
