<template>
  <section>
    <v-card class="mb-8">
      <v-card-title>Produs de baza</v-card-title>
      <v-card-text>
        <base-product-select class="w-full mb-4" />
        <short-name-recipe class="w-full" />
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Subcategorii</v-card-title>
      <v-card-text>
        <category-selector class="w-full sm:w-1/2 md:w-1/3" />
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Configureaza Componentele</v-card-title>
      <v-card-text>
        <components-selector />
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Extra Garantie</v-card-title>
      <v-card-text>
        <extra-warranty-selector />
      </v-card-text>
    </v-card>
    <v-card class="mb-8">
      <v-card-title>Extra Optiuni</v-card-title>
      <v-card-text>
        <extra-options-selector></extra-options-selector>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-title>Publicarea produselor</v-card-title>
      <v-card-text>
        <publish-locations />
      </v-card-text>
      <v-card-actions>
        <v-btn class="mr-2 primary-button" color="primary" type="filled" @click="generate"
          >Salveaza</v-btn
        >
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import PublishLocations from "@/views/ProductGenerator/components/PublishLocations";
import ErrorMessages from "@/components/general-form/ErrorMessages";
import BaseProductSelect from "@/views/ProductGenerator/components/BaseProductSelect";
import CategorySelector from "@/views/ProductGenerator/components/CategorySelector";
import ComponentsSelector from "@/views/ProductGenerator/components/ComponentsSelector";
import ShortNameRecipe from "@/views/ProductGenerator/components/ShortNameRecipe";
import ExtraWarrantySelector from "@/views/ProductGenerator/components/ExtraWarrantySelector";
import ExtraOptionsSelector from "@/views/ProductGenerator/components/ExtraOptionsSelector.vue";

export default {
  name: "ProductsGenerator",
  components: {
    ExtraWarrantySelector,
    ShortNameRecipe,
    ComponentsSelector,
    CategorySelector,
    BaseProductSelect,
    ErrorMessages,
    PublishLocations,
    ExtraOptionsSelector,
  },
  methods: {
    generate() {
      this.$store.dispatch("productGenerator/generate").then(() => {
        this.$router.push({
          name: "products-category",
          params: {
            categoryId: this.$store.getters["productGenerator/categoryId"],
          },
        });
      });
    },
  },
  created() {
    this.$store.commit("productGenerator/RESET");
    this.$store.commit(
      "productGenerator/SET_CATEGORY_ID",
      this.$route.params.categoryId
    );
  },
};
</script>
