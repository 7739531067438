<template>
  <div>
    <extra-options-selector-dialog :extraOptions="extraOptions" />
    <v-simple-table v-show="selectedExtraOptions.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Categorie extra optiune</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="extraOption in selectedExtraOptions" :key="extraOption.id">
            <td>{{ extraOption.name }}</td>
            <td>
              <v-btn icon color="error" @click="deleteExtraOption(extraOption)">
                <v-icon> fal fa-trash </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import ExtraOptionsSelectorDialog from "@/components/autocompletes/ExtraOptionsSelectorDialog";

export default {
  name: "ExtraOptionsSelector",
  components: {
    ExtraOptionsSelectorDialog,
  },
  data() {
    return {
      extraOptions: [],
    };
  },
  computed: {
    selectedExtraOptions() {
      return this.$store.getters["productGenerator/selectedExtraOptions"];
    },
  },
  methods: {
    deleteExtraOption(extraOption) {
      this.$store.dispatch("productGenerator/deleteExtraOption", extraOption);
    },
    loadItems(search) {
      search = search || "";
      this.$http.get(`/product-extra-options/categories`).then(({ data }) => {
        this.extraOptions = data.data.map((item) => {
          return {
            id: item.id,
            name: item.internal_name,
          };
        });
        this.loading = false;

        console.log("extraOptions", this.extraOptions);
      });
    },
  },
  created() {
    this.loadItems();
  },
};
</script>

<style scoped>
</style>
