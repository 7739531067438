<template>
  <div>
    <label>
      Status:
      <select v-model="selected" class="fallback-status-select">
        <option v-for="(status,index) in statuses" :key="index" :value="index">
          {{ status }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FallbackStock',
  props: {
    value: {}
  },
  computed: {
    statuses () {
      return this.$store.getters['general/productStatuses']
    },
    selected:{
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

.fallback-status-select {
  border: 1px solid #282828;
  padding: 5px;
  @apply cursor-pointer;
}
</style>
