<template>
  <tr>

    <td>{{ currentWarrantyObject.name }}</td>

    <td>{{ currentWarrantyObject.number_of_months }}</td>
    <td>{{ currentWarrantyObject.percent_value }}</td>
    <td>
      <v-btn icon color="error" @click="deleteWarranty">
        <v-icon>
          fal fa-trash
        </v-icon>
      </v-btn>
    </td>

  </tr>
</template>

<script>
export default {
  name: 'SingleWarrantyDisplay',
  props: {
    warranty: {
      required: true
    }
  },
  computed: {
    warrantiesObjects () {
      return this.$store.getters['productGenerator/bufferWarranties']
    },
    currentWarrantyObject () {
      return this.warrantiesObjects.find(item => item.id === this.warranty)
    }
  },
  methods:{
    deleteWarranty(){
      this.$store.dispatch('productGenerator/deleteWarranty', this.warranty)
    }
  }
}
</script>

<style scoped>

</style>
