<template>
  <div>
    <div class="my-6">
      <div>
        <vs-input v-model="newComponent.name" class="w-full" label="Denumire Scurtă rețetă"/>
        <error-messages :errors="errors.name"/>
      </div>
      <div>
        <vs-input v-model="newComponent.internal_name" class="w-full" label="Denumire Gestiune/Site"/>
        <error-messages :errors="errors.internal_name"/>
      </div>
      <div>

      </div>
      <div class="row">
        <div class="col-6">

          <vs-input v-model="newComponent.price" class="w-full" label="Pret vanzare"/>
          <error-messages :errors="errors.price"/>
        </div>
        <div class="col-6">

          <vs-input v-model="newComponent.buying_price" class="w-full" label="Pret achizitie"/>
          <error-messages :errors="errors.buying_price"/>
        </div>
      </div>
      <vs-input v-model="newComponent.stock" class="w-full" label="Stoc"/>
      <error-messages :errors="errors.stock"/>
    </div>
    <div>
      <vs-button :disabled="loading" class="mr-6" @click="createComponent">Salveaza</vs-button>
      <vs-button :disabled="loading" color="danger" type="border" @click="cancel">Anuleaza</vs-button>
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddNewSimpleComponent',
  components: {ErrorMessages},
  props: {
    componentType: {
      required: true
    }
  },
  data () {
    return {
      loading: false,
      newComponent: {
        name: '',
        internal_name: '',
        show_as_product: false,
        price: '',
        buying_price: '',
        stock: '',
        component_type_id: ''
      },
      errors: {}
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
      this.newComponent = {
        name: '',
        internal_name: '',
        show_as_product: false,
        price: '',
        buying_price: '',
        stock: '',
        component_type_id: this.componentType.id
      }
    },
    createComponent () {
      this.loading = true
      this.$http.post('product-components', this.newComponent)
        .then(({data}) => {
          this.$emit('created', data)
          this.cancel()
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: error.message,
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })

    }
  },
  mounted () {
    this.newComponent.component_type_id = this.componentType.id
  }
}
</script>
