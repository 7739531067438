<template>
  <div>
    <product-extra-warranty-selector-dialog @input="selectWarranty"/>
    <v-simple-table v-show="selectedWarranties.length">
      <template v-slot:default>
        <thead>
        <tr>
          <th>Nume</th>
          <th>Nr. Luni</th>
          <th>Procent</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <single-warranty-display v-for="warranty in selectedWarranties" :key="warranty" :warranty="warranty"/>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import ProductExtraWarrantySelectorDialog from '@/components/autocompletes/ProductExtraWarrantySelectorDialog'
import SingleWarrantyDisplay from '@/views/ProductGenerator/components/ExtraWarrantySelector/SingleWarrantyDisplay'

export default {
  name: 'ExtraWarrantySelector',
  components: {
    SingleWarrantyDisplay,
    ProductExtraWarrantySelectorDialog
  },
  computed: {
    selectedWarranties () {
      return this.$store.getters['productGenerator/selectedWarranties']
    }
  },
  methods: {
    selectWarranty (warranty) {
      this.$store.dispatch('productGenerator/selectWarranty', warranty.id)
      this.$store.dispatch('productGenerator/addBufferWarranty', warranty)
    }
  }
}
</script>

<style scoped>

</style>
